.headerEmpty {
    display: flex;
    height: 100px;
}

.header {
    position: fixed;
    backdrop-filter: blur(5px);
    top: 0px;
    display: flex;
    height: 100px;
    width: -webkit-fill-available;
    align-items: center;
    padding: 0px 32px;
    justify-content: space-between;
    z-index: 99;
}

.menuIcon {
    padding: 16px;
    border-radius: 50%;
    cursor: pointer;
    display: none;
}

/* .menuIcon:hover {
    background-color: var(--light-gray);
} */

.titleSection {
    display: flex;
    align-items: center;
    min-width: 180px;
    cursor: pointer;
    text-decoration: none;
}

.side {
    display: flex;
    align-items: center;
}

.subSideMenu {
    display: flex;
    align-items: center;
}

.menu {
    display: flex;
    align-items: center;
}

.menuSpace {
    margin-left: 32px;
}

.title {
    font-size: 24px;
    font-weight: bold;
    margin-left: 8px;
    color: black;
}

.menuItem {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 40px;
    border-radius: 8px;
    color: black;
    font-size: 16px;
    font-weight: bold;
}

.link {
    text-decoration: none;
    color: black;
}

.menuItem:hover {
    background-color: var(--deep-gray);
}

.menuItem.selected {
    color: var(--dark-gold);
}

@media (max-width: 768px) {

    .header {
        background-color: var(--white);
        opacity: 0.8;
        padding: 0px 16px;
    }

    .headerEmpty,
    .header {
        height: 64px;
    }

    .image {
        display: none;
    }

    .menuIcon {
        display: flex;
        -webkit-tap-highlight-color: transparent;
    }

    .menuList {
        position: absolute;
        width: 100%;
        flex-direction: column;
        top: 64px;
        right: 0;
        background-color: var(--white);
        overflow: hidden;
    }

    .menuList.hide {
        transition: max-height 200ms;
        max-height: 0px;
    }

    .menuList.show {
        transition: max-height 200ms;
        max-height: calc(240px + 16px);
        padding-bottom: 32px;
    }

    .menu {
        width: 100%;
        flex-direction: column;
    }

    .menuSpace,
    .button {
        margin-left: 0;
    }

    .menuItem:hover,
    .button:hover {
        background-color: var(--white);
    }
}