.main {
    display: flex;
    flex-direction: column;
    
}
.imageConatiner {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--beige);
    padding: 32px 0px;

}
.image {
    width: 50%;
}

.link {
    display: flex;
    font-size: 24px;
    font-weight: bold;
    margin-top: 32px;
    margin-left: 88px;
}

.sectionTitle {
    display: flex;
    font-size: 24px;
    font-weight: bold;
    margin-top: 64px;
    margin-bottom: 48px;
    margin-left: 88px;
}

.warning {
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    background-color: var(--beige);
    flex: 1;
    padding: 32px;
    margin: 0px 64px;
    font-size: 18px;
    font-family: 'KoPub Batang';
    font-weight: 500;
    line-height: 1.8rem;
    white-space: pre-wrap;
    color: var(--light-black);
    box-shadow: var(--box-shadow);
}

.warning2 {
    /* background-color: var(--light-blue); */
}

.warning3 {
    /* background-color: var(--mint); */
}

/* .warningFocus {
    color: var(--dark-blue);
    font-weight: bold;
} */

/* .warningBlur {
    color: var(--dark-gray);
} */

.example {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    padding-top: 32px;
    font-size: 18px;
    font-weight: bold;
}

.exampleImageContainer {
    display: flex;
    flex-direction: column;
}

.requestImage {
    max-width: 900px;
    width: 100%;
    border-radius: 16px;
    margin-top: 16px;
}

@media (max-width: 768px) {
    .image {
        width: 80%;
    }

    .link {
        display: flex;
        font-size: 24px;
        font-weight: bold;
        margin-left: 0;
        justify-content: center;
    }

    .sectionTitle {
        margin-top: 48px;
        margin-bottom: 32px;
        margin-left: 52px;
    }

    .warning {
        margin: 0px 0px;
        border-radius: 0;
    }

}