@font-face {
    font-family: 'KoPub Batang';
    font-weight: 700;
    font-style: bold;
    src: url('./KoPubWorld_Batang_Pro_Bold.otf') format('opentype');
}

@font-face {
    font-family: 'KoPub Batang';
    font-weight: 400;
    font-style: normal;
    src: url('./KoPubWorld_Batang_Pro_Light.otf') format('opentype');
}

@font-face {
    font-family: 'KoPub Batang';
    font-weight: 500;
    font-style: medium;
    src: url('./KoPubWorld_Batang_Pro_Medium.otf') format('opentype');
}