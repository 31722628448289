.home {}

.topSection {
    position: relative;
    background-image: url('../../../public/images/home-top.png');
    background-position: top center;
    background-size: cover;
    padding: 15% 0%;
    overflow: hidden;
}

.backgroundOvelay {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.textContainer {
    font-size: 48px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.text {
    color: var(--gold);
    margin-bottom: 19px;
}

.textDesc {
    color: var(--black);
    white-space: pre;
    text-align: center;
    line-height: 3.5rem;
}

.link {
    text-decoration: none;
    color: var(--black)
}

.sectionTitle {
    font-size: 24px;
    font-weight: bold;
    margin-top: 70px;
    margin-bottom: 48px;
    margin-left: 88px;
}

.intro {
    display: flex;
    border-radius: 32px;
    background-color: var(--beige);
    padding: 32px;
    margin: 0px 64px;
    box-shadow: var(--box-shadow);
    align-items: center;
}

.profileImage {
    width: 40%;
    margin-right: 48px;
}

.profileTextBumper {
    display: flex;
    flex: 1;
}

.profileText {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 24px;
    font-family: 'KoPub Batang';
    font-weight: bold;
    text-align: center;
    white-space: pre-wrap;
}

.reservation {
    display: flex;
    margin: 0px 64px;
}

.reservationPage {
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    background-color: var(--baby-pink);
    flex: 1;
    padding: 32px;
    color: var(--light-black);
    box-shadow: var(--box-shadow);
    /* text-align: center; */
}

.reservationPage:not(:last-child) {
    margin-right: 16px;
}

.reservationTitle {
    font-size: 20px;
    font-weight: bold;
}

.message {
    font-size: 18px;
    margin-top: 32px;
    white-space: pre-wrap;
    line-height: 2rem;
    font-family: 'KoPub Batang';
    font-weight: bold;
    /* color: var(--red); */
}

.desc {
    font-size: 18px;
    margin-top: 32px;
    white-space: pre-wrap;
    line-height: 2rem;
    font-family: 'KoPub Batang';
    font-weight: bold;
    color: var(--hotpink);
    /* color: var(--red); */
}

.review {
    display: flex;
    margin: 0px 64px;
    border-radius: 32px;
    background-color: var(--light-blue);
    overflow: auto;
    box-shadow: var(--box-shadow);
}

.swiperContainer {
    display: flex;
}

.slide {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
}

.page {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    white-space: pre-wrap;
    line-height: 2.2rem;
    padding: 64px;
}

.reviewPhoto {
    width: 120px;
    border-radius: 50%;
    background-color: var(--white);
    margin-bottom: 32px;
}

@media (max-width: 992px) {

    .textContainer {
        font-size: 32px;
        font-weight: 500;
    }

    .textDesc {
        line-height: 2.5rem;
    }

    .profileImage {
        margin-right: 32px;
    }

    .profileText {
        font-size: 24px;
    }
}

@media (max-width: 768px) {

    .textContainer {
        font-size: 20px;
        font-weight: 700;
    }

    .textDesc {
        line-height: 2.4rem;
    }

    .intro {
        flex-direction: column;
        align-items: center;
        border-radius: 0;
    }

    .profileImage {
        width: 100%;
        margin-right: 0px;
        margin-bottom: 32px;
    }

    .profileText {
        font-size: 16px;
    }

    .reservation {
        flex-direction: column;
        margin: 0px 0px;
        border-radius: 0;
    }

    .reservationPage:not(:last-child) {
        margin-bottom: 32px;
        margin-right: 0;
        flex: unset;
    }

    .page {
        font-size: 16px;
        line-height: 1.4rem;
    }

    .sectionTitle {
        display: flex;
        margin-top: 48px;
        margin-bottom: 32px;
        margin-left: 32px;
    }

    .intro {
        margin: 0px 0px;
    }

    .review {
        margin: 0px 0px;
        border-radius: 0;
    }

    .page {
        padding: 32px;
    }
}