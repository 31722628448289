.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 64px;
    margin-top: 32px;
    font-size: 16px;
    font-weight: 500;
    color: var(--dark-gray);
    background-color: var(--gray-white);
}

.icon {
    cursor: pointer;
}

.icons {
    display: flex;
}

.icon:not(:last-child) {
    margin-right: 16px;
}

.image {
    width: 32px;
    height: 32px;
}

.footerTitle {
    white-space: pre-wrap;
    /* text-align: center; */
    line-height: 2rem;
}

@media (max-width: 768px) {
    .footer {
        justify-content: center;
        padding: 32px 32px 64px 32px;
        flex-direction: column;
    }

    .icons {
        margin-top: 32px;
    }
}