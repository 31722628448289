.main {
    display: flex;
    flex-direction: column;
}

.review {
    display: flex;
    /* background-color: var(--beige); */
    background-color: var(--light-blue);
    box-shadow: var(--box-shadow);
}

.swiperContainer {
    display: flex;
}

.slide {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
}

.slideDeep {
    background-color: var(--little-light-blue);
}

.page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 32px;
}

.image {
    max-width: 400px;
    width: 100%;
}

.imageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.link {
    display: flex;
    font-size: 24px;
    font-weight: bold;
    margin-top: 32px;
    margin-bottom: 32px;
    margin-left: 88px;
}

@media (max-width: 768px) {
    .page {
        padding: 16px;
    }

    .link {
        display: flex;
        font-size: 24px;
        font-weight: bold;
        margin-left: 0;
        justify-content: center;
    }
}