* {
    box-sizing: border-box;
}

body {
    font-family: 'Noto Sans KR', sans-serif;
}

:root {
    --white: #FFFFFF;
    --gray-white: #FAFAFA;
    --deep-gray: #EAEAEA;
    --black: #000000;
    --red: #FF1010;
    --hotpink: #ff0074;
    --beige: #F6EFE7;
    --gold: #E39920;
    --dark-gold: #FBAA0E;
    --light-gray: #EEEEEE;
    --primary-button: #531AFF;
    --dark-gray: #5F5F5F;
    --light-black: #242424;
    --dark-blue: #4775A0;
    /* --blue-sky: #ADBFE2; */
    --light-blue: #B2C7DA;
    --light-violet: #F3E7F6;
    --deep-violet: #D9AAEB;
    --little-light-blue: #ABC0D1;
    --baby-pink: #FFD7E5;
    --mint: #B8E8D9;
    --box-shadow: 0px 15px 15px 0px rgba(0, 0, 0, 0.1);
}